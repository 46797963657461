





















import Vue from "vue";
import store from "@/store";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

export default Vue.extend ({
    components: {
        PageTitleHeader,
        ResourcesContent: () => {
            const options = store.getters.getPageOptions("resources");
            const componentToUse = options?.components?.content;
            return import(`./resources-content/${componentToUse}`);
        }
    },
    computed: {
        isMidsummer() {
            return this.$store.getters.layoutOptions.siteName === "midsummer";
        }
    }
    
})
